import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  constructor(
    private library: FaIconLibrary,
    private translateService: TranslateService
  ) {
    this.library.addIconPacks(fas);
    translateService.setDefaultLang('en');
    const language = localStorage.getItem('bellAppLanguage');
    if (!language) {
      translateService.use('rs');
    }
  }

  ngOnInit(): void {
    this.checkLanguage();
  }

  checkLanguage() {
    const language = localStorage.getItem('bellAppLanguage');
    if (!language) {
      localStorage.setItem('bellAppLanguage', 'rs');
    }else {
      this.translateService.use(language);
    }
  }
}
