import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private router: Router) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const headers: any = {};

    if (localStorage.getItem('jwt')) {
      headers['Authorization'] = 'Bearer ' + localStorage.getItem('jwt');
    }

    request = request.clone({
      setHeaders: headers
    });

    return next.handle(request);
  }
}
